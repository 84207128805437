<template>
  <div class="container">
    <page-block
      title="Остались вопросы?"
      subtitle="Напишите Ваш вопрос в поле ниже и введите телефон. Мы ответим в Telegram, Viber или WhatsАpp или перезвоним."
      align="center"
      :page-body="true"
    >
      <template #prehead>
        <div class="que-image">
          <img src="../assets/que.svg" alt="" />
        </div>
      </template>
      <div class="make-question">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-8 col-xl-9">
            <textarea
              v-model="form.text"
              :disabled="ordering || success"
              :class="{ error: errors.includes('text') }"
              placeholder="Напишите свой вопрос..."
              rows="4"
              @input="changeText"
            ></textarea>
          </div>
          <div class="col-12 col-md-6 col-lg-4 col-xl-3">
            <input
              type="text"
              v-maska="mask"
              v-model="form.phone"
              :disabled="ordering || success"
              placeholder="Введите телефон"
              :class="{ error: errors.includes('phone') }"
              @input="changePhone"
            />
            <button @click="makeQuestion()" v-if="!success">
              {{ ordering ? "Обработка..." : "Отправить вопрос" }}
            </button>
            <div class="success-text" v-if="success">Вопрос отправлен!</div>
          </div>
        </div>
      </div>
    </page-block>
  </div>
</template>

<script>
import PageBlock from "@/components/PageBlock";
import { CREATE_QUESTION } from "@/store/modules/order";
import { SET_USER_PHONE } from "@/store/modules/user";

export default {
  name: "QuestionForm",
  components: {
    PageBlock,
  },
  data() {
    return {
      ordering: false,
      success: false,
      errors: [],
      form: {
        phone: "",
        text: "",
      },
      mask: "+# (###) ###-##-##",
    };
  },
  watch: {
    "form.phone"(value) {
      if (value.length <= 18) {
        this.mask = "+# (###) ###-##-###";
      } else {
        this.mask = "+## (###) ###-##-##";
      }
    },
  },
  methods: {
    makeQuestion() {
      let self = this;
      if (!this.ordering && !this.success) {
        this.ordering = true;
        this.errors = [];
        this.$store
          .dispatch(CREATE_QUESTION, this.form.text)
          .then(() => {
            self.$metrika.reachGoal("question");
            self.success = true;
            self.ordering = false;
          })
          .catch((errors) => {
            self.errors = errors;
            self.ordering = false;
          });
      }
    },
    changePhone() {
      if (this.errors.includes("phone"))
        this.errors = this.errors.map((v) => v != "phone");
      this.$store.commit(SET_USER_PHONE, this.form.phone);
    },
    changeText() {
      if (this.errors.includes("text"))
        this.errors = this.errors.map((v) => v != "text");
    },
  },
};
</script>

<style lang="scss" scoped>
.que-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  @media (max-width: 768px) {
    height: 200px;
    img {
      width: 120px;
    }
  }
  @media (min-width: 991px) {
    margin-top: -70px;
  }
}

.make-question {
  textarea {
    background: #72727263;
    border-radius: 10px;
    padding: 13px 50px 13px 20px;
    color: #fff;
    font-size: 18px;
    border: none;
    width: 100%;
    resize: none; /* Запрещаем изменять размер */
  }
  input {
    background: #f1f1f1;
    border-radius: 10px;
    padding: 12px 50px 12px 20px;
    color: #505050;
    font-size: 18px;
    //border: none;
    width: 100%;
    margin-bottom: 15px;
    @media (max-width: 768px) {
      margin-top: 10px;
    }
  }
  button {
    padding: 12px 0px 11px;
    background: #2a93f4;
    border-radius: 10px;
    font-weight: 700;
    font-size: 20px;
    border: none;
    width: 100%;
    color: #fff;
  }
}

.success-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 18px;

  span {
    color: #0086fc;
    padding: 0 5px;
  }
}
</style>
